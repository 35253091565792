import React, { useState } from 'react';
import Footer from './Footer';
import '../Service.css'; // Assuming you have a CSS file for styling

const Service = () => {
  const services = {
    Bookkeeping: {
      title: 'Accounting & Bookkeeping',
      img: "/images/Picture1.jpg",
      head: "Accounting & Bookkeeping",
     description: (
  <>
    <p>
      Our Accounting and bookkeeping services encompass the meticulous management of financial records and transactions for Individuals, Business, or Organizations. Here are the details of our services:
    </p>
    <ul>
      <li>
        <p>
          <strong>Financial Record Management:</strong> Our services involve the systematic recording, organizing, and tracking of financial transactions. This includes purchases, sales, receipts, and payments.
        </p>
      </li>
      <li>
        <p>
          <strong>Financial Reporting:</strong> Our professionals in this field generate financial reports like Income Statements, Balance Sheets, and Cash Flow Statements. These reports provide insights into the financial health and performance of an entity.
        </p>
      </li>
      <li>
        <p>
          <strong>Budgeting and Forecasting:</strong> Our team assists you in creating budgets and financial forecasts based on historical data and future projections. This helps businesses plan and make informed financial decisions.
        </p>
      </li>
      <li>
        <p>
          <strong>Tax Preparation and Compliance:</strong> Our services typically include preparing and filing tax returns for individuals, businesses, and organizations. Ensuring compliance with UAE Tax laws and regulations is a crucial aspect of this service.
        </p>
      </li>
      <li>
        <p>
          <strong>Audit Support:</strong> We also provide support during audits by preparing necessary documentation, explaining financial processes, and ensuring compliance with auditing standards.
        </p>
      </li>
      <li>
        <p>
          <strong>Financial Analysis:</strong> Beyond recording transactions, our team analyzes financial data to identify trends, assess performance, and offer strategic recommendations for improving financial efficiency and profitability.
        </p>
      </li>
      <li>
        <p>
          <strong>Payroll Processing:</strong> Our accounting services also offer payroll processing, managing employee compensation, tax withholdings, and payroll reporting.
        </p>
      </li>
      <p>
        In summary, our accounting and bookkeeping services encompass a range of financial management activities aimed at maintaining accurate records, ensuring compliance, facilitating decision-making, and optimizing financial performance.
      </p>
    </ul>
  </>
)
 },
    Audit: {
      title: 'Audit and Assurance',
      img: "/images/Picture2.jpg",
      head: "Audit and Assurance",
    description: (
  <>
    <p>
      Audit and assurance services play a critical role in providing confidence and credibility to financial information. Here's how you might describe them:
    </p>
    <ul>
      <li>
        <p>
          <strong>Independent Examination:</strong> Our Audit and Assurance involve an independent and objective examination of financial statements, transactions, systems, and processes to ensure their accuracy, reliability, and compliance with relevant standards and regulations.
        </p>
      </li>
      <li>
        <p>
          <strong>Verification of Financial Information:</strong> We ensure the accuracy and completeness of financial information presented in an organization's financial statements. This includes confirming the existence, valuation, and ownership of assets, as well as the completeness and accuracy of liabilities.
        </p>
      </li>
      <li>
        <p>
          <strong>Risk Assessment:</strong> We assess the risk factors affecting an organization's financial reporting process, including internal controls, potential fraud, and compliance risks. This helps stakeholders to understand the reliability of the financial information provided.
        </p>
      </li>
      <li>
        <p>
          <strong>Compliance Monitoring:</strong> We evaluate whether financial statements comply with applicable accounting standards, regulations, and legal requirements. This ensures that the organization's financial reporting is transparent and follows best practices.
        </p>
      </li>
      <li>
        <p>
          <strong>Internal Control Evaluation:</strong> Our services include assessing the effectiveness of internal control systems designed to safeguard assets, prevent fraud, and ensure the accuracy of financial reporting. Recommendations for improvement are provided where weaknesses are identified.
        </p>
      </li>
      <li>
        <p>
          <strong>Assurance on Non-Financial Information:</strong> Beyond financial data, we may also provide assurance on non-financial information such as sustainability reports, corporate governance practices, and other key performance indicators.
        </p>
      </li>
      <li>
        <p>
          <strong>Stakeholder Confidence:</strong> The primary objective of audit and assurance services is to enhance stakeholders' confidence in the reliability and integrity of financial information. This includes shareholders, investors, lenders, regulators, and other interested parties.
        </p>
      </li>
      <li>
        <p>
          <strong>Reporting and Communication:</strong> Audit findings and conclusions are typically communicated through the auditor's report, which provides an opinion on the fairness of the financial statements and any relevant disclosures. This report is essential for stakeholders to make informed decisions.
        </p>
      </li>
      <li>
        <p>
          <strong>Continuous Improvement:</strong> We also provide recommendations for improving financial reporting processes, internal controls, and risk management practices based on our findings. This helps organizations enhance efficiency, effectiveness, and transparency.
        </p>
      </li>
      <p>
        In summary, audit and assurance services serve to provide stakeholders with confidence in the accuracy, reliability, and compliance of financial information, ultimately contributing to sound decision-making and trust in the financial markets.
      </p>
    </ul>
  </>
)


    },
    VatRegistration: {
      title: 'Value Added TAX (VAT)',
      img: "/images/Picture3.jpg",
      head: "Value Added TAX (VAT)",
     description: (
  <>
    <p>
      Since the implementation of Value Added Tax in UAE, our experienced team has endeavored to explain and simplify the VAT process for their clients while helping in a simplified fashion to ensure their business functions are VAT compliant. This allows our clients to focus on their business and not get overwhelmed by the tax imposition. We offer the following VAT-related services:
    </p>
    <ul>
      <li>
        <p>
          <strong>VAT Registration, De-registration, and Group Registration:</strong> Navigating the VAT registration process is crucial for businesses. We assist in the registration, de-registration, and group registration, ensuring that your business is compliant with VAT regulations.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Assessment:</strong> In case of VAT assessment, we provide support to address any queries or concerns, ensuring that you are well-prepared to meet regulatory requirements.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Health Check:</strong> Our VAT health check service reviews your VAT processes and documentation to identify potential issues and ensure compliance.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Record Amendments:</strong> For any necessary amendments to your VAT records, we provide guidance and support to maintain accurate and up-to-date records.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Training:</strong> Understanding VAT is key to compliance. We offer training to equip you and your team with the knowledge and skills required to manage VAT effectively.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Return Filing:</strong> Efficient VAT return filing is essential for compliance. Our experts streamline the process, making sure your returns are accurate and submitted on time.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Reconsideration:</strong> In case of disputes or reconsideration, we represent your interests and work towards a fair resolution.
        </p>
      </li>
      <li>
        <p>
          <strong>Penalty Reconsideration:</strong> If you are facing VAT penalties, we assist in seeking reconsideration and minimizing potential financial impacts.
        </p>
      </li>
      <li>
        <p>
          <strong>Tax Audit Representation:</strong> In case of a tax audit, we provide expert representation and guidance throughout the audit process.
        </p>
      </li>
      <li>
        <p>
          <strong>VAT Refund:</strong> We facilitate the VAT refund process for eligible businesses, ensuring a smooth and efficient refund experience for our clients.
        </p>
      </li>
    </ul>
  </>
)

    },
    VatReturnFiling: {
      title: 'Corporate Tax (CT)',
      img: "/images/Picture4.jpg",
      head: "Corporate Tax (CT)",
      description:  (
        <>
          <p>Our Corporate Tax Service assists you in successfully implementing Corporate Tax to your business in compliance with the tax regime, filing your tax returns, and providing complete tax support through the following steps.</p>
          <p><strong>Corporate Tax Registration:</strong> Our tax consultant will assist you in the registration of Corporate Tax (CT), ensuring that your business is compliant with CT regulations.</p>
          <p><strong>Assessment of Tax Obligations:</strong> Our tax consultant will review your business activities, financial records, and other relevant information to determine the taxes that your business is required to pay.</p>
          <p><strong>Tax Planning:</strong> Based on the assessment, the tax consultant will advise on tax-saving strategies and opportunities to minimize your tax liability.</p>
          <p><strong>Preparation and Filing of Tax Return:</strong> The tax consultant will prepare corporate tax reports and file Corporate tax returns on behalf of your business, ensuring accuracy and compliance with tax regulations.</p>
          <p><strong>Payment of Corporate Tax:</strong> The tax consultant will ensure that your business pays the tax on time and in the correct amount to avoid any penalties.</p>
          <p><strong>Representation in case of Tax Audit:</strong> In the event of a tax audit, the tax consultant will represent your business and provide support and guidance to ensure a smooth process.</p>
          <p><strong>Ongoing support and advice:</strong> The tax consultant will provide ongoing support and advice on tax-related matters to ensure that your business remains compliant with tax regulations.</p>
        </>
      )
    },
    VatHealthCheck: {
      title: 'Excise Tax',
      img: "/images/Picture5.png",
      head: "Excise Tax",
      description: (
        <>
          <p>Excise tax is an indirect tax generally levied on goods that are considered harmful to the health of the general public. The aim of the excise tax is therefore to discourage the consumption of those specific goods by the general public.</p>
          <p>It is payable by any person engaged in the activities listed below, specifically:</p>
          <p><strong>Importers of excise goods</strong></p>
          <p><strong>Producers of excise goods</strong></p>
          <p><strong>Persons releasing goods from an excise tax designated zone</strong></p>
          <p><strong>Warehouse keepers</strong></p>
          <p><strong>Stockpilers of excise goods, in certain cases</strong></p>
          <p>There may also be a limited number of cases where excise tax becomes the duty of another person involved in any of the above activities, where the person originally liable for the tax fails to pay. In all other cases, where a business or consumer purchases excise goods within the UAE, they can usually expect that excise tax has been accounted for on the goods within the UAE by the supplier (and will be included in the purchase price for the goods). In certain cases, excise goods will be physically labeled to indicate that excise tax has been paid in respect of those goods.</p>
          <p>We can assist you on excise tax issues and will be providing below services on your demand:</p>
          <p><strong>Excise Tax Registration</strong></p>
          <p><strong>Excise Tax Return Filing</strong></p>
          <p><strong>Excise Designated Zone Registration</strong></p>
          <p><strong>Excise Compliance Services</strong></p>
          <p><strong>Excise Product Registration</strong></p>
        </>
      )
    },
    CorporateTaxRegistration: {
      title: 'Economic Substance Regulation (ESR)',
      img: "/images/Picture6.png",
      head: "Economic Substance Regulation (ESR)",
      description: (
        <>
          <p>Economic Substance Regulation became effective in the UAE on January 01, 2019. This regulation requires companies to fill two broad forms:</p>
          <p><strong>Notification Form</strong></p>
          <p><strong>Annual Substance Return</strong></p>
          <p>The filing requirements depend upon the Regulatory Authority, which is relevant to the business, thus causing it to vary. It is essential for every business in the UAE to assess whether they fall under the UAE Economic Substance rules and what their obligations are. We provide assistance with the following services in order to avoid non-compliance penalties.</p>
          <p><strong>Business Activities evaluation eligible for Economic Substance Reporting</strong></p>
          <p><strong>Analysis and notification to the Relevant Authorities</strong></p>
          <p><strong>Support in filling Annual ESR Return</strong></p>
          <p><strong>ESR Compliance</strong></p>
        </>
      )
    },
    BusinessProcessAnalysis: {
      title: 'Management Consultancy',
      img: "/images/Picture7.jpg",
      head: "Management Consultancy",
      description: (
        <>
          <p>The constant challenge that every company faces is to gain an advantage against its competitors while satisfying its stakeholders. Our specialist consultants will provide assistance throughout the journey, starting from the formation of the company. They will ensure the company is equipped with the needed expertise and knowledge to survive in the market by assisting decision making, implementing strategic policies, reducing costs, and much more. The following services are offered by our experienced consultants.</p>
          <ul>
            <li>Company Formation</li>
            <li>Change Management</li>
            <li>Corporate Governance</li>
            <li>Strategy Development</li>
            <li>Policies & Procedures</li>
            <li>Internal Controls</li>
            <li>Feasibility Studies</li>
            <li>Legal Services</li>
          </ul>
        </>
      )
    }, 
    ERP: {
      title: 'Odoo ERP',
      img: "/images/Picture8.png",
      head: "Odoo ERP",
     description: (
  <>
    <p>
      Odoo ERP offers a comprehensive suite of business management applications designed to streamline operations and drive growth for organizations of all sizes. Here are a few benefits of using Odoo:
    </p>
    <ul>
      <li>
        <strong>All-in-One Solution:</strong> Odoo integrates various business functions such as E-commerce, CRM, Sales, Purchase, Inventory, Accounting, HR, Social Media Marketing, and more into a single platform, eliminating the need for maintaining multiple ERPs.
      </li>
      <li>
        <strong>Customization and Scalability:</strong> With its modular structure and open-source architecture, Odoo allows businesses to tailor the software to their specific needs and scale as their requirements evolve.
      </li>
      <li>
        <strong>Cost-Effective:</strong> Odoo's open-source nature means lower initial costs compared to proprietary software. Additionally, its modular approach enables businesses to select only the applications they need, reducing unnecessary expenses.
      </li>
      <li>
        <strong>User-Friendly Interface:</strong> Odoo boasts an intuitive and user-friendly interface, making it easy for employees to adopt and use the software with minimal training.
      </li>
      <li>
        <strong>Streamlined Processes:</strong> By automating repetitive tasks and integrating business processes, Odoo helps organizations optimize efficiency, reduce errors, and improve productivity.
      </li>
      <li>
        <strong>Real-Time Insights:</strong> Odoo provides real-time visibility into key business metrics and performance indicators through customizable dashboards and reports, enabling informed decision-making.
      </li>
      <li>
        <strong>Mobile Accessibility:</strong> With mobile-responsive design and native mobile apps, Odoo allows users to access and manage their business operations from anywhere, anytime, enhancing flexibility and responsiveness.
      </li>
      <li>
        <strong>Comprehensive Ecosystem:</strong> From modules and apps to integrations and third-party extensions, Odoo offers a rich ecosystem of solutions to address diverse business needs and industry-specific requirements.
      </li>
    </ul>
    <p>
      Our Odoo implementation methodology comprises several key components and considerations that are vital for successful implementation:
    </p>
    <ul>
      <li>
        <strong>Needs Assessment:</strong> We conduct a thorough assessment of the client's business requirements, processes, and objectives to determine how Odoo can best meet their needs. These involve workshops, interviews, and documentation review to understand the scope of the project.
      </li>
      <li>
        <strong>Project Planning:</strong> We develop a detailed project plan outlining the implementation timeline, milestones, resources, roles, and responsibilities. Establish clear communication channels and project governance structures to ensure effective collaboration between the implementation team and the client.
      </li>
      <li>
        <strong>Configuration and Customization:</strong> Configure Odoo to align with the client's specific business requirements. This involves setting up modules, workflows, user roles, permissions, and custom applications. Customization may also be required to tailor Odoo to unique business processes or industry-specific needs.
      </li>
      <li>
        <strong>Data Migration:</strong> Migrate existing data from legacy systems or spreadsheets into Odoo to ensure a smooth transition and continuity of operations. This includes data cleansing, mapping, transformation, and validation to ensure data accuracy and integrity.
      </li>
      <li>
        <strong>Integration:</strong> Integrate Odoo with other systems and applications within the client's IT ecosystem, such as accounting software, CRM systems, e-commerce platforms, or third-party APIs. Seamless integration enables data flow and synchronization across different systems, eliminating silos and improving efficiency.
      </li>
      <li>
        <strong>Training and Change Management:</strong> Provide comprehensive training sessions for end-users to familiarize them with Odoo's features, functionality, and workflows. Develop training materials, documentation, and user guides to support ongoing learning and adoption. Additionally, implement change management strategies to address resistance to change and facilitate organizational buy-in.
      </li>
      <li>
        <strong>Testing and Quality Assurance:</strong> Conduct rigorous testing of the Odoo implementation to identify and address any issues or discrepancies. This includes functional testing, integration testing, performance testing, and user acceptance testing to ensure that the system meets the client's requirements and expectations.
      </li>
      <li>
        <strong>Go-Live Support:</strong> Provide support and assistance during the go-live phase to ensure a smooth transition to the new Odoo system. Address any last-minute issues, perform final validations, and provide ongoing support to mitigate any disruptions to business operations.
      </li>
      <li>
        <strong>Post-Implementation Support:</strong> Offer post-implementation support services to address any ongoing maintenance, optimization, or enhancement needs. This may include troubleshooting, bug fixes, system upgrades, and additional customization or configuration based on evolving business requirements.
      </li>
      <li>
        <strong>Documentation and Knowledge Transfer:</strong> Document the Odoo configuration, customizations, processes, and best practices for future reference and knowledge transfer. This enables the client to maintain and support the system independently and empowers users to leverage Odoo effectively in their day-to-day operations.
      </li>
    </ul>
  </>
)

    },
    ErpAuditServices: {
      title: 'Hyperion Financial Management (HFM):',
      img: "/images/Picture9.png",
      head: "Hyperion Financial Management (HFM):",
      description: (
  <>
    <p>
      Hyperion Financial Management (HFM) is a comprehensive financial consolidation and reporting tool developed by Oracle. Here are some key points about Hyperion Financial Management:
    </p>
    <ul>
      <li>
        <strong>Financial Consolidation:</strong> HFM facilitates the consolidation of financial data from various sources, such as different subsidiaries or business units within an organization. It helps in aggregating this data to generate consolidated financial statements.
      </li>
      <li>
        <strong>Data Integration:</strong> HFM allows integration with various data sources, including Enterprise Resource Planning (ERP) systems, spreadsheets, and other financial applications. This integration enables users to pull in data seamlessly for reporting and analysis purposes.
      </li>
      <li>
        <strong>Complex Calculations:</strong> HFM supports complex financial calculations and business rules necessary for accurate financial reporting. It can handle currency translation, intercompany eliminations, allocations, and other financial calculations efficiently.
      </li>
      <li>
        <strong>GAAP and IFRS Compliance:</strong> HFM is designed to comply with Generally Accepted Accounting Principles (GAAP) and International Financial Reporting Standards (IFRS). It ensures that financial statements produced adhere to the required accounting standards and regulations.
      </li>
      <li>
        <strong>Data Quality Management:</strong> HFM includes features for data validation, data integrity checks, and audit trails to ensure the accuracy and reliability of financial data. This helps in maintaining data quality and ensures compliance with regulatory requirements.
      </li>
      <li>
        <strong>Financial Reporting and Analysis:</strong> HFM provides robust reporting and analysis capabilities, allowing users to create various financial reports, including balance sheets, income statements, cash flow statements, and other financial analyses. Users can customize reports based on their specific requirements.
      </li>
      <li>
        <strong>Workflow and Collaboration:</strong> HFM includes workflow management features that streamline the financial consolidation process. It allows users to define approval hierarchies, manage review cycles, and track the status of consolidation tasks. Collaboration tools enable multiple users to work on the same financial data concurrently.
      </li>
      <li>
        <strong>Security and Access Control:</strong> HFM offers robust security features to control access to financial data and ensure data confidentiality. It allows administrators to define user roles, permissions, and data access levels based on organizational requirements.
      </li>
      <li>
        <strong>Scalability and Performance:</strong> HFM is designed to handle large volumes of financial data and support the needs of complex organizations with multiple subsidiaries and business units. It offers scalability and performance optimizations to ensure efficient processing of financial consolidation tasks.
      </li>
      <li>
        <strong>Integration with Other Hyperion Products:</strong> HFM can be integrated with other Oracle Hyperion products, such as Hyperion Planning and Hyperion Essbase, to provide end-to-end financial management solutions. This integration enables seamless data flow between different financial processes within an organization.
      </li>
    </ul>
    <p>
      Overall, Hyperion Financial Management is a powerful tool for financial consolidation, reporting, and analysis, offering features to streamline the financial close process, improve data accuracy, and ensure regulatory compliance.
    </p>
  </>
)

      
    },
    BusinessValuation: {
      title: 'Oracle Application:',
      img: "/images/Picture10.png",
      head: "Oracle Application:",
      description: (
  <>
    <p>
      Oracle Applications is a suite of integrated business applications designed to automate and streamline various business processes across different departments within an organization. Here are some key points about Oracle Applications:
    </p>
    <ul>
      <li>
        <strong>Comprehensive Suite:</strong> Oracle Applications offer a comprehensive suite of business applications that cover various functional areas such as finance, human resources, supply chain management, customer relationship management (CRM), enterprise performance management (EPM), and more.
      </li>
      <li>
        <strong>Modular Architecture:</strong> Oracle Applications follow a modular architecture, allowing organizations to select and implement specific modules based on their business needs. This modular approach enables flexibility in deployment and scalability as the organization grows.
      </li>
      <li>
        <strong>Integrated Solution:</strong> Oracle Applications are designed to work seamlessly together, providing integrated solutions for end-to-end business processes. Integration between modules ensures smooth data flow and eliminates data silos, enabling better decision-making and improved efficiency.
      </li>
      <li>
        <strong>Scalability and Flexibility:</strong> Oracle Applications are scalable to meet the needs of both small businesses and large enterprises. They offer flexibility in configuration and customization to adapt to unique business requirements, industry-specific processes, and regulatory compliance.
      </li>
      <li>
        <strong>Industry-Specific Solutions:</strong> Oracle offers industry-specific solutions tailored to meet the unique requirements of different sectors such as manufacturing, retail, healthcare, financial services, and more. These industry-specific applications include pre-configured business processes and best practices to accelerate implementation and reduce customization efforts.
      </li>
      <li>
        <strong>Cloud-based Deployment:</strong> Oracle Applications are available for deployment on-premises or in the cloud. Oracle Cloud Applications offer advantages such as scalability, agility, reduced IT infrastructure costs, automatic updates, and accessibility from anywhere with an internet connection.
      </li>
      <li>
        <strong>Mobile and Social Capabilities:</strong> Oracle Applications include mobile and social capabilities that enable users to access and interact with business data anytime, anywhere, and from any device. Mobile applications provide on-the-go access to key business functions, while social collaboration features facilitate communication and collaboration among users.
      </li>
      <li>
        <strong>Analytics and Business Intelligence:</strong> Oracle Applications incorporate analytics and business intelligence capabilities to help organizations gain insights from their data and make data-driven decisions. Built-in reporting tools, dashboards, and analytics empower users to analyze trends, identify opportunities, and optimize business performance.
      </li>
      <li>
        <strong>Security and Compliance:</strong> Oracle Applications prioritize security and compliance to protect sensitive business data and ensure regulatory adherence. They offer robust security features, including role-based access controls, encryption, audit trails, and data masking, to safeguard against security threats and maintain data integrity.
      </li>
      <li>
        <strong>Continuous Innovation:</strong> Oracle is committed to continuous innovation and invests heavily in research and development to enhance its applications with new features, functionalities, and technologies. Regular updates and releases ensure that Oracle Applications remain up-to-date with the latest industry trends and customer requirements.
      </li>
    </ul>
    <p>
      Overall, Oracle Applications provide organizations with powerful tools to streamline business processes, drive operational efficiency, improve collaboration, and achieve business goals effectively.
    </p>
  </>
)

      
    },
    
  };

  const [selectedService, setSelectedService] = useState(Object.keys(services)[0]); // Select the first service by default

  return (
    <>
      <div className='mianabout'>
        {/* Add any main about content here */}
      </div>

      <div className='container-fluid mt-5'>
        <div className='row'>
          <div className='col-md-5'>
            {Object.keys(services).map((key) => (
              <button
                key={key}
                onClick={() => setSelectedService(key)}
                className={`py-2 bookkeeping mb-2 ${selectedService === key ? 'selected' : ''}`}
              >
                {services[key].title}
              </button>
            ))}
          </div>
          <div className='col-md-7'>
            {selectedService && (
              <>
                <h4 className='taxi'>{services[selectedService].title}</h4>
                <img
                  src={services[selectedService].img}
                  alt={services[selectedService].title}
                  className='service-image'
                />
                <div className='discription_div firstcss'>

                <h4 className='mt-3 '>{services[selectedService].head}</h4>
                <p className='text-capitalize'>{services[selectedService].description}</p>
                </div>
             
              </>
            )}
          </div>
        </div>
      </div>

      {/* <div className='container-fluid mt-5'>
    <div className='row'>
      <div className='col-md-5'>


      </div>

      <div className='col-md-7'>
      
        <div className='mt-5'>
        <div>
            <h3 className='solutionkmd'>Solution We Give</h3>
        </div>
        <div className='d-flex mt-4'>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Tailored Financial Solutions</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Strategic Financial Planning</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Efficient Bookkeeping and Accounting</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Cash Flow Optimization</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Inventory Control and Valuation</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Payroll Precision</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Global Accounting Standards Adherence</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Software Proficiency</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Informed Decision Support</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Expert Guidance</h4>
        </div>
        <div className='d-flex '>
            <img className='tickservice' src='/images/check mark.png'/>
            <h4 className='hkkk'>Commitment to Growth</h4>
        </div>
        
        </div>

      </div>
    </div>
</div>
      



   */}




<div className='container-fluid consultation mt-5 py-3'>
    <div className='row'>
        <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>

        </div>
        <div className='col-md-4 gone'> <button className="quotett px-4 py-2 mt-4 ">Get Free Consulting</button></div>
    </div>
</div>

      <Footer />
    </>
  );
};

export default Service;
