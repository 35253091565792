import React from 'react'
import '../Contact.css'
import Footer from './Footer'

const Team = () =>{
    return(
        <>
                         <div className='mianabout'>
    <img className='aboutfirstimage img-fluid' alt='network error' src='/images/groupaboutt.png'/>
    <div className='about-overlay'>
<h1 className='firstcont'>OUR TEAM</h1>
<p className='firstcont'>HOME<span className='landus'>/ABOUT US/OUR TEAM</span></p>
    </div> 
    </div>



{/* TEAM */}

    <div className='container-fluid latestproject'>
<div className='row'>
<div className='col'>
<p className=" text-center mt-5 team team-header"><span className="linesagain"></span>MEET OUR TEAM</p>
<h1 className='text-center mt-3'>ALL THE PEOPLE BEHIND THE LATEST PROJECT</h1>
<div class="team-section">
  
  <p class="team-description">
    Our dedicated professional team leverages extensive business acumen to serve all our clients. Upholding values of integrity, objectivity, professionalism, honesty, confidentiality, and unwavering commitment to excellence, we strive to alleviate the burdens of financial management and compliance...
  </p>
  <div class="team-container container-fluid">
  <div className='row team_subdiv'>

  
    <div className="team-member col-md-3">
      <img className='ceo_image' src="/images/ceo.png" alt=""/>
      <div className="member-info">
        <p className="member-name teamcolor">Mr. Muhammad Ikram</p>
        <p className="member-title teamcolor">Partner</p>
      </div>
    </div>
    <div className="team-member col-md-3">
        <img className='ceo_image' src="/images/partner.png" alt="Mubeen Baho"/>
        <div className="member-info">
          <p className="member-name teamcolor">Mubeen Baho</p>
          <p className="member-title teamcolor">Partner</p>
        </div>
      </div>
      <div className="team-member col-md-3">
        <img className='ceo_image' src="/images/itconsultant.png" alt="Salman Saboor"/>
        <div className="member-info">
          <p className="member-name teamcolor">Salman Saboor</p>
          <p className="member-title teamcolor">IT Consultant</p>
        </div>
      </div>
      <div className="team-member col-md-3">
        <img className='ceo_image' src="/images/businessconsultant.png" alt="Habib Ahmed"/>
        <div className="member-info">
          <p className="member-name teamcolor">Habib Ahmed</p>
          <p className="member-title teamcolor">Business Consultant</p>
        </div>
      </div>
    
      </div>
  </div>
</div>

</div>

</div>

</div>


<div className="container-fluid cardonesecond">
   <div className="row">
      <div className="col-md-6 px-5 ">
        <img className="img-fluid strategylike" src="/images/strategy.png" alt="img"/>
      </div>
        <div className="col-md-6 px-5  mt-5">
        {/* <p className="statusaboutagain"><span className="lines responsive-heading"></span>P R O F E S S I O N A L   <span className="skills">S K I L L S</span>  </p> */}
        <h2 className="mt-5 text-capitalize">We Make Your Business Goals Our Priority</h2>

<div className="skill">
    <p className="skill-name">VAT & Corporate Tax Planning</p>
    <div className="progress-bar">
      <div className="progressone" >85%</div>
    </div>
</div>
  <div className="skill">
    <p className="skill-name">Accounting & Bookkeeping</p>
    <div className="progress-bar">
      <div className="progresstwo " >95%</div>
    </div>
  </div>
  <div className="skill">
    <p className="skill-name">Audit & Assurance</p>
    <div className="progress-bar">
      <div className="progressthree" >80%</div>
    </div>
  </div>
  <div className="skill">
    <p className="skill-name">ERP Implementation & Support</p>
    <div className="progress-bar">
      <div className="progressfour" >90%</div>
    </div>
  </div>
 </div>
     </div>
  </div>






    <div className='container-fluid consultation mt-5 py-3'>
    <div className='row'>
        <div className='col-md-8'>
            <h1 className='ready'>Get Free Consultation Now! We Are Ready To Help Your Business</h1>

        </div>
        <div className='col-md-4 gone'> <button className="quotett px-4 py-2 mt-4 ">Get Free Consulting</button></div>
    </div>
</div>

<Footer/>
        </>
    )
}
export default Team;